import { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listAction as categoryListAction } from "../../network/store/action/CategoryResponseAction";
import { listAction as subCategoryListAction } from "../../network/store/action/SubCategoryResponseAction";

import "./products.css";
import {
  formUpdateData,
  updateFormInfo,
} from "../../redux/slice/formUpdateSlice";
import { useNavigate } from "react-router-dom";
import { updateMessage } from "../../redux/slice/toastSlice";
import AppToast from "../../components/appToast";
import { createUpdateAction } from "../../network/store/action/ProductResponseAction";
import { ItemImage } from "semantic-ui-react";

function ProductUpdate() {
  // to get api data

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formData = useSelector(formUpdateData);
  const itemInfo = formData.product;
  const [sectionList, setSectionList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const initialState = {
    productName: "",
    cal_grm: "",
    cal_percent: "",
    fat_grm: "",
    fat_percent: "",
    carbs_grm: "",
    carbs_percent: "",
    protein_grm: "",
    protein_percent: "",
    summary: "",
    sectionId: "",
    subCategoryId: "",
    description:"",
  };

  const [productAddForm, setproductAddForm] = useReducer(
    (state, updates) => ({ ...state, ...updates }),
    initialState
  );

  const [inputError, setInputError] = useState({
    productName: itemInfo.product_name != "" ? true : false,
    cal_grm: itemInfo.cal_grm != "" ? true : false,
    cal_percent: true, //itemInfo.cal_percent != "" ? true : false,
    fat_grm: itemInfo.fat_grm != "" ? true : false,
    fat_percent: itemInfo.fat_percent != "" ? true : false,
    carbs_grm: itemInfo.carbs_grm != "" ? true : false,
    carbs_percent: itemInfo.carbs_percent != "" ? true : false,
    protein_grm: itemInfo.protein_grm != "" ? true : false,
    protein_percent: itemInfo.protein_grm != "" ? true : false,
  });

  useEffect(() => {
    console.log("Product info", itemInfo);
    if (itemInfo != undefined) {
      let updateValues = {
        id: itemInfo.id,
        productName: itemInfo.product_name,
        description:itemInfo.description,
        cal_grm: itemInfo.cal_grm,
        cal_percent: "",
        fat_grm: itemInfo.fat_grm,
        fat_percent: itemInfo.fat_percent,
        carbs_grm: itemInfo.carbs_grm,
        carbs_percent: itemInfo.carbs_percent,
        protein_grm: itemInfo.protein_grm,
        protein_percent: itemInfo.protein_grm,
        summary: itemInfo.summary,
        sectionId: itemInfo.category_id,
        subCategoryId: itemInfo.subcategory_id,
      };
      setproductAddForm(updateValues);
    }

    getSections();
    getCategories("");
  }, []);

  const getSections = async () => {
    await dispatch(categoryListAction({}, dispatch)).then((response) => {
      console.log("sectionList", response.result);
      if (response.result) {
        setSectionList(response.result);
      }
    });
  };

  const onSubmtClick = async () => {
    if (checkValidation()) {
      let itemObj = {
        name: productAddForm.productName,
        description:productAddForm.description,
        cal_grm: productAddForm.cal_grm,
        cal_percent: productAddForm.cal_percent,
        fat_grm: productAddForm.fat_grm,
        carbs_grm: productAddForm.carbs_grm,
        carbs_percent: productAddForm.carbs_percent,
        protein_grm: productAddForm.protein_grm,
        protein_percent: productAddForm.protein_percent,
        summary:
          "There are " +
          productAddForm.cal_grm +
          " calories in 100 grams of <b>" +
          productAddForm.productName +
          "</b> \n  Calories Breakdown: <b>" +
          productAddForm.fat_percent +
          "g </b> of Fat, <b>" +
          productAddForm.carbs_percent +
          "% </b> carbs, <b>" +
          productAddForm.protein_percent +
          "%</b> protein.",
        categoryId: productAddForm.sectionId,
        subCategoryId: productAddForm.subCategoryId,
      };

      if (itemInfo != undefined) {
        itemObj.id = itemInfo.id;
      }

      await dispatch(createUpdateAction(itemObj, dispatch)).then((response) => {
        if (response.result) {
          dispatch(
            updateMessage({
              display: true,
              message: "Product Updated.",
            })
          );
          navigate(-1);
        }
      });
    } else {
      dispatch(
        updateMessage({
          display: true,
          message: "Form Error.",
        })
      );
    }
  };

  useEffect(() => {
    // setproductAddForm({ subCategoryId: "" });
  }, [productAddForm.sectionId]);

  const getCategories = async (id) => {
    setCategoryList([]);
    //
    await dispatch(
      subCategoryListAction(
        {
          secId: id,
        },
        dispatch
      )
    ).then((response) => {
      //   console.log("sectionList", response.result);
      if (response.result) {
        setCategoryList(response.result);
      }
    });
  };

  const handleChanges = (e) => {
    console.log("SDebug > handleChanges", e);

    if (e.target.name === "sectionId") {
      setCategoryList([]);

      if (e.target.value !== "") {
        setproductAddForm({ subCategoryId: "" });
        getCategories(e.target.value);
      }
    }

    setproductAddForm({ ...productAddForm, [e.target.name]: e.target.value });
  };

  const checkValidation = () => {
    var isFormValid = false;

    if (
      inputError.productName === true &&
      inputError.cal_grm === true &&
      //inputError.cal_percent === true &&
      inputError.fat_grm === true &&
      inputError.fat_percent === true &&
      inputError.carbs_grm === true &&
      inputError.carbs_percent === true &&
      inputError.protein_grm === true &&
      inputError.protein_percent === true
    ) {
      isFormValid = true;
    }
    return isFormValid;
  };
  return (
    <>
      <AppToast />
      <main className="container-fluid dashboard">
        <div className="row justify-content-around tble">
          <div style={{ marginLeft: "50px" }}>
            <h3 className="dashboard-title">Update Product</h3>
          </div>
        </div>
        <div className="row m-3">
          <div className="col-md-3">
            <div className="form-group">
              <label>Category</label>
              <select
                name="sectionId"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={productAddForm.sectionId}
              >
                <option value={""}>{"--Select Category--"}</option>
                {sectionList &&
                  sectionList.length > 0 &&
                  sectionList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Category</label>
              <select
                name="subCategoryId"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={productAddForm.subCategoryId}
              >
                <option value={""}>{"--Select Sub Category--"}</option>
                {categoryList &&
                  categoryList.length > 0 &&
                  categoryList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="col-md-5">
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                name="productName"
                className="form-control"
                value={productAddForm.productName}
                onChange={(e) => {
                  setproductAddForm({
                    ...productAddForm,
                    productName: e.target.value,
                  });
                  e.target.value !== "" &&
                    setInputError({ ...inputError, productName: true });
                }}
              />
              {productAddForm.productName === "" && (
                <span className="text-danger">*Required</span>
              )}
            </div>
          </div>
          <div className="col-md-5">
            <div className="form-group">
              <label>Description</label>
              <input
                type="text"
                name="description"
                className="form-control"
                value={productAddForm.description}
                onChange={(e) => {
                  setproductAddForm({
                    ...productAddForm,
                    description: e.target.value,
                  });
                 
                }}
              />
              
            </div>
          </div>
          <p className="mt-3 d-flex flex-row" style={{ marginBottom: "10px" }}>
            <b>Nutritional Information per 100G **</b>
          </p>

          <div className="product-container">
            <div className="d-flex flex-row" style={{ marginBottom: "5px" }}>
              <p className="calories-para-width align-middle">Calories</p>
              <span className="calories-span">:</span>
              <input
                type="number"
                required
                placeholder="grams"
                className="login-input-outline"
                value={productAddForm.cal_grm}
                onChange={(e) => {
                  setproductAddForm({
                    ...productAddForm,
                    cal_grm: e.target.value,
                  });
                  e.target.value !== "" &&
                    setInputError({ ...inputError, cal_grm: true });
                }}
              />
              <span style={{ marginLeft: "5px", marginRight: "20px" }}>g</span>
              {/* <input
                type="number"
                required
                placeholder="%"
                className="login-input-outline"
                value={productAddForm.cal_percent}
                onChange={(e) => {
                  setproductAddForm({
                    ...productAddForm,
                    cal_percent: e.target.value,
                  });
                  e.target.value !== "" &&
                    setInputError({ ...inputError, cal_percent: true });
                }}
              />
              <span style={{ marginLeft: "5px", marginRight: "20px" }}>%</span> */}
              {productAddForm.cal_grm === "" && (
                <span className="text-danger">*Required</span>
              )}
            </div>
            <div className="d-flex flex-row" style={{ marginBottom: "5px" }}>
              <p className="calories-para-width">Fat</p>
              <span className="calories-span">:</span>
              <input
                type="number"
                required
                placeholder="grams"
                className="login-input-outline"
                value={productAddForm.fat_grm}
                onChange={(e) => {
                  setproductAddForm({
                    ...productAddForm,
                    fat_grm: e.target.value,
                  });
                  e.target.value !== "" &&
                    setInputError({ ...inputError, fat_grm: true });
                }}
              />
              <span style={{ marginLeft: "5px", marginRight: "20px" }}>g</span>
              <input
                type="number"
                required
                placeholder="%"
                className="login-input-outline"
                value={productAddForm.fat_percent}
                onChange={(e) => {
                  setproductAddForm({
                    ...productAddForm,
                    fat_percent: e.target.value,
                  });
                  e.target.value !== "" &&
                    setInputError({ ...inputError, fat_percent: true });
                }}
              />
              <span style={{ marginLeft: "5px", marginRight: "20px" }}>%</span>
              {(productAddForm.fat_percent === "" ||
                productAddForm.fat_grm === "") && (
                <span className="text-danger">*Required</span>
              )}
            </div>

            <div className="d-flex flex-row" style={{ marginBottom: "5px" }}>
              <p className="calories-para-width">Carbs</p>
              <span className="calories-span">:</span>
              <input
                type="number"
                required
                placeholder="grams"
                className="login-input-outline"
                value={productAddForm.carbs_grm}
                onChange={(e) => {
                  setproductAddForm({
                    ...productAddForm,
                    carbs_grm: e.target.value,
                  });
                  e.target.value !== "" &&
                    setInputError({ ...inputError, carbs_grm: true });
                }}
              />
              <span style={{ marginLeft: "5px", marginRight: "20px" }}>g</span>
              <input
                type="number"
                required
                placeholder="%"
                className="login-input-outline"
                value={productAddForm.carbs_percent}
                onChange={(e) => {
                  setproductAddForm({
                    ...productAddForm,
                    carbs_percent: e.target.value,
                  });
                  e.target.value !== "" &&
                    setInputError({ ...inputError, carbs_percent: true });
                  console.log(e.target.value);
                }}
              />
              <span style={{ marginLeft: "5px", marginRight: "20px" }}>%</span>
              {(productAddForm.carbs_percent === "" ||
                productAddForm.carbs_grm === "") && (
                <span className="text-danger">*Required</span>
              )}
            </div>

            <div className="d-flex flex-row" style={{ marginBottom: "5px" }}>
              <p className="calories-para-width">Protein</p>
              <span className="calories-span">:</span>
              <input
                type="number"
                required
                placeholder="grams"
                className="login-input-outline"
                value={productAddForm.protein_grm}
                onChange={(e) => {
                  setproductAddForm({
                    ...productAddForm,
                    protein_grm: e.target.value,
                  });
                  e.target.value !== "" &&
                    setInputError({ ...inputError, protein_grm: true });
                }}
              />
              <span style={{ marginLeft: "5px", marginRight: "20px" }}>g</span>
              <input
                type="number"
                required
                placeholder="%"
                className="login-input-outline"
                value={productAddForm.protein_percent}
                onChange={(e) => {
                  setproductAddForm({
                    ...productAddForm,
                    protein_percent: e.target.value,
                  });
                  e.target.value !== "" &&
                    setInputError({ ...inputError, protein_percent: true });
                }}
              />
              <span style={{ marginLeft: "5px", marginRight: "20px" }}>%</span>
              {(productAddForm.protein_percent === "" ||
                productAddForm.protein_grm === "") && (
                <span className="text-danger">*Required</span>
              )}
            </div>
          </div>

          {productAddForm.cal_grm && (
            <>
              <h5 className="mt-4">Summary</h5>
              <p className="summary-para">
                There are{" "}
                <span className="summary-span">
                  {productAddForm.cal_grm} calories{" "}
                </span>
                in 100 grams of <b>{productAddForm.productName}</b>,
                <br />
                Calories Breakdown:{" "}
                <span className="summary-span">
                  {productAddForm.fat_percent}%
                </span>{" "}
                Fat,{" "}
                <span className="summary-span">
                  {productAddForm.carbs_percent}%
                </span>{" "}
                carbs,{" "}
                <span className="summary-span">
                  {productAddForm.protein_percent}%
                </span>{" "}
                protein.
              </p>
            </>
          )}

          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              style={{ display: "flex", justifyContent: "center" }}
              type="button"
              className="btn btn-purple-bg"
              onClick={() => onSubmtClick()}
            >
              Submit
            </button>
            <button className="btn btn-white-bg" onClick={() => navigate(-1)}>
              Cancel
            </button>
          </div>
        </div>
      </main>
    </>
  );
}
export default ProductUpdate;
