import React, { useEffect, useState } from "react";
import OrderItem from "./unique store items/Order-Item";
import axios from "axios";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { STORE_API_PATH } from "../../../network/config/apiPaths";

function UniqueStoreOrders(props) {
  const [order] = useState(props.id);
  const [resultList, setResultList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectMonth, setMonth] = useState("");
  const [filterList, setFilterList] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [err, setErr] = useState("");
  const [orderCount, setOrderCount] = useState([]);
  const [phoneFilter, setphoneFilter] = useState("");
  const month = [
    { id: "01", name: "JAN" },
    { id: "02", name: "FEB" },
    { id: "03", name: "MARCH" },
    { id: "04", name: "APRIL" },
    { id: "05", name: "MAY" },
    { id: "06", name: "JUNE" },
    { id: "07", name: "JULY" },
    { id: "08", name: "AUG" },
    { id: "09", name: "SEP" },
    { id: "10", name: "OCT" },
    { id: "11", name: "NOV" },
    { id: "12", name: "DEC" },
  ];

  useEffect(() => {
    StoreOrdersApi();
    storeOrderCount();
  }, []);

  const StoreOrdersApi = async () => {
    setIsLoading(true);
    let data = await axios.get(
      API_CONFIG.API_HOST +
        STORE_API_PATH.UNIQUE_STORE_ORDERS +
        order +
        "&startDate=" +
        fromDate +
        "&endDate=" +
        toDate,
      {
        headers: API_HEADERS,
      }
    );
    console.log(data.data.result);
    setIsLoading(false);
    if (data.data.result !== null) {
      setResultList(data.data.result);
      setFilterList(data.data.result);
    } else {
      setResultList([]);
      setFilterList([]);
    }
  };

  const storeOrderCount = async () => {
    let data = await axios.get(
      API_CONFIG.API_HOST +
        STORE_API_PATH.UNIQUE_ORDERS_COUNT +
        `storeId=${order}&range=custom&startDate=${fromDate}&endDate=${toDate}`,
      {
        headers: API_HEADERS,
      }
    );
    console.log(data.data.result);
    setOrderCount(data.data.result);
  };

  const filterMonth = (e) => {
    setMonth(e.target.value);
    console.log(e.target.value);
    setIsLoading(true);
    let month = e.target.value;
    let data = resultList.filter((item) => {
      // console.log(item.dated.getMonth())
      if (item.dated.substring(5, 7) == month) {
        return item;
      }
    });
    console.log("data..", data);
    setIsLoading(false);
    setFilterList(data);
  };
  const filterFromDate = (e) => {
    setFromDate(e.target.value);
    if (new Date(e.target.value) > new Date(toDate))
      setErr("enter valid dates");
    else setErr("");
    // console.log(e.target.value);
    // setIsLoading(true);
    // let month = e.target.value;
    // let data = resultList.filter((item) => {
    //   // console.log(item.dated.getMonth())
    //   if (item.dated === month) {
    //     return item;
    //   }
    // });
    // console.log("data..", data);
    // setIsLoading(false);
    // setFilterList(data);
  };

  const validateDates = (e) => {
    setToDate(e.target.value);
    if (new Date(fromDate) > new Date(e.target.value))
      setErr("enter valid dates");
    else setErr("");
  };

  const FilterByPhone = (e) => {
    setphoneFilter(e.target.value);
    console.log(e.target.value);
    setIsLoading(true);
    let month = e.target.value;
    let data = resultList.filter((item) => {
      // console.log(item.dated.getMonth())
      if (item.phone.includes(month)) {
        return item;
      }
    });
    console.log("data..", data);
    setIsLoading(false);
    setFilterList(data);
  };

  return (
    <>
      <div className="row mb-3">
        {/* <div className='col-2'>
        <label>Month</label>
          <select value={selectMonth} className='form-control' onChange={filterMonth}>
            <option>{"--select month--"}</option>
            {
              month.map(item=>{
                return(
                  <option value={item.id}>{item.name}</option>
                )
              })
            }
          </select>
        </div> */}
        <div className="col-2">
          <label>From</label>
          <input
            type="date"
            onChange={filterFromDate}
            value={fromDate}
            className="form-control"
          />
        </div>
        <div className="col-2">
          <label>To</label>
          <input
            type="date"
            className="form-control"
            onChange={validateDates}
          />

          <span className="text-danger">{err}</span>
        </div>
        {/* <div className="col-3">
          <label></label>
          <input type="search" className="form-control" />
        </div> */}

        <div className="col-3">
          <label></label>
          <label></label>
          <br/>
          <button
            type="button"
            class="btn btn-danger me-2"
            onClick={() => StoreOrdersApi()}
          >
            Apply
          </button>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-2">
          <div className="dashboard-div d-flex flex-column justify-content-center">
            {orderCount.received} <br /> Orders 
            Recieved
          </div>
        </div>
        <div className="col-2">
          <div className="dashboard-div d-flex flex-column justify-content-center">
            {orderCount.accepted} <br /> Orders Accepted
          </div>
        </div>
        <div className="col-2">
          <div className="dashboard-div d-flex flex-column justify-content-center">
            {orderCount.shipped} <br /> Orders  Shipped
          </div>
        </div>
        <div className="col-2">
          <div className="dashboard-div d-flex flex-column justify-content-center">
            {orderCount.delivered} <br /> Orders Delivered
          </div>
        </div>
        <div className="col-2">
          <div className="dashboard-div d-flex flex-column justify-content-center">
            {orderCount.cancelled} <br /> Orders Cancelled
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-3 mb-3">
          <input
            type="search"
            value={phoneFilter}
            onChange={FilterByPhone}
            className="form-control"
            placeholder="search by phone number"
          />
        </div>

        <div className="col-md-12 card-1">
          <table className="table table-hover align-middle">
            <thead className="thead-dark">
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Date</th>
                <th scope="col">Order No</th>
                <th scope="col">Order Amount</th>
                <th scope="col">Customer Name</th>
                <th scope="col">Phone Number</th>
                <th scope="col">D.Location</th>
                <th scope="col">Coupon</th>

                <th scope="col">Status</th>
                <th scope="col">D Boy</th>
              </tr>
            </thead>

            <tbody>
              {isLoading ? (
                <>Loading</>
              ) : filterList && filterList.length > 0 ? (
                filterList.map((obj, i) => {
                  return <OrderItem item={obj} />;
                })
              ) : (
                <>No data</>
              )}
            </tbody>
            {console.log("result...", resultList)}
            {console.log("filter...", filterList)}
            {console.log("from", fromDate)}
            {console.log("to", toDate)}
            {console.log(err)}
          </table>
        </div>
      </div>
    </>
  );
}

export default UniqueStoreOrders;
