import axios from "axios";
import { useEffect, useState } from "react";
import AutoComplete from "react-google-autocomplete";
import { useLocation, useNavigate } from "react-router-dom";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";


function UserEdit() {
    const navigate = useNavigate();
    const location=useLocation();
    const item=location.state!==null?location.state.item:"";
    const [franchiseUsers,setFranchiseUsers]=useState([]);
    const [inputvalidation, setInputValidation] = useState({
        name: item.name,
        phone: item.phone,
        radius: item.radius,
        percentage: item.fo_share,
        location: item.city,
        fo_owner:item.franchiseOwner,
    });
    const labelStyle = {
        fontWeight: "bold",

    };
    const [city, setCity] = useState("");
    const [areaName, setAreaName] = useState("")
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0)

    useEffect(()=>{
        FranchiseOwnerApi()
    },[latitude])

    const updatePlaceInfo = (place) => {
        if (place && place.address_components != null) {
            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                switch (addressType) {
                    case "locality":
                        setAreaName(place.address_components[i].long_name)

                        break;
                    case "administrative_area_level_3":
                        setCity(place.address_components[i].long_name)


                        break;
                    // case "country":
                    //   setCountry(place.address_components[i].long_name);
                    //   break;
                }
            }
        }
        console.log("place..", place);

        setLatitude(place.geometry.location.lat())
        setLongitude(place.geometry.location.lng())
    };

    const handleChange = (e) => {
        let { name, val } = e.target
        setInputValidation(
            {
                ...inputvalidation,
                [name]: val
            }
        )
    }

    const FranchiseOwnerApi= async ()=>{
        const data=await axios.get(API_CONFIG.API_HOST+`User/getNearbyUsers?lat=${latitude}&lng=${longitude}&roleId=9`,{
          headers:API_HEADERS,
        })
        console.log("in api..",data.data.result)
        setFranchiseUsers(data.data.result)
      }
    
      const SelectFranchiseOwner = () =>{
        
        return(
          <select
          className="form-control"
          name="fo_owner"
          value={inputvalidation.fo_owner}
          onChange={handleChange}
        >
          <option value={""}>{"--select Franchise Owner--"}</option>
          {franchiseUsers&&franchiseUsers.map((item, i) => {
            return (
              <option key={i} value={item.id}>
                {item.name}
              </option>
            );
          })}
        </select>
        )
      }

    return (
        <>
            <div
                style={{
                    position: "fixed",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    width: "100%",
                    height: "100%",
                    zIndex: "999",
                    overflow: "scroll",
                }}
            >
                <div className="d-flex align-item-center justify-content-center">
                    <div className="col-md-8">
                        <div className="container">
                            <div className="row mt-5">
                                <div className="col-md-5 card-1 p-5">



                                    <h4 className="text-center">Update Franchise Owner</h4>


                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label style={labelStyle}>Search City/Town</label>{" "}
                                            {/* {inputValidation.place_address} */}
                                            <AutoComplete
                                                className="form-control"
                                                style={{ width: "100%" }}
                                                name="location"
                                                options={{
                                                    types: ["geocode", "establishment"],
                                                }}
                                                onChange={(e) => handleChange(e)}
                                                value={inputvalidation.location}
                                                apiKey={"AIzaSyDSOtXI8CvDwqWfP6_JbV0tsmj5ymSPL8k"}
                                                onPlaceSelected={(place) => updatePlaceInfo(place)}
                                                placeholder="Enter city name"
                                            />

                                            <label>Name</label>
                                            <input
                                                type="text"
                                                onChange={handleChange}
                                                name="name"
                                                value={inputvalidation.name}
                                                className="form-control"

                                            />

                                            <label>Phone number</label>
                                            <input
                                                type="number"
                                                onChange={handleChange}
                                                name="phone"
                                                value={inputvalidation.phone}
                                                className="form-control"

                                            />

                                            {
                                                item.role_id==="7" ?<>
                                                <label>Franchise owner</label>
                                                {
                                                    SelectFranchiseOwner()
                                                }
                                                </>:""
                                            }

                                            {item.role_id==="9"?<>
                                                <label>Franchise Percentage</label>
                                            <input
                                                type="number"
                                                onChange={handleChange}
                                                name="percentage"
                                                value={inputvalidation.percentage}

                                                className="form-control"

                                            />
                                            </>:""}

                                           {
                                            item.role_id==="9" ?
                                            <>
                                             <label>Radius</label>
                                            <input
                                                type="number"
                                                onChange={handleChange}
                                                name="radius"
                                                value={inputvalidation.radius}
                                                

                                                className="form-control"

                                            />
                                            </>:""
                                           }

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    marginTop: 20,
                                                }}
                                            >
                                                <button
                                                    style={{ display: "flex", justifyContent: "center" }}
                                                    type="button"
                                                    className="btn btn-purple-bg"

                                                >
                                                    Submit
                                                </button>
                                                <button
                                                    className="btn btn-white-bg"
                                                    onClick={() => {
                                                        // dispatch(
                                                        //   updateFormInfo({
                                                        //     displayForm: false,
                                                        //     formData: {},
                                                        //   })
                                                        // );
                                                        navigate(-1)
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserEdit;