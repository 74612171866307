import { useEffect, useState } from "react"
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig"
import { ORDER_API_PATH } from "../../network/config/apiPaths"
import { axiosApi } from "../../network/service/config/AaxiosUtil"
import axios from "axios"
import { useLocation, useNavigate } from "react-router-dom"
import DBItem from "./DbItem"

function OrderAssign(){
    const location=useLocation();
    const item=location.state.status
    console.log("item",item)
    const [dbList,setDbList]=useState([])
    const [selectDb,setSelectDb]=useState("");
    const navigate=useNavigate();

    useEffect(()=>{
       dbListApiCall()
    },[])

    const dbListApiCall=async()=>{
        let path=API_CONFIG.API_HOST+ORDER_API_PATH.ASSIGN_DB+`lat=${item.address.latitude}&lng=${item.address.longitude}&city=${item.storeItems[0].store.city}&roleId=${7}`
        let data=await axios.get(path,{
            headers:API_HEADERS
        })
        console.log("db",data.data.result)
        setDbList(data.data.result)

    }
    
    const dbOptions=()=>{
        {
        return(
            <>
            {
               dbList&&dbList.length>0&& dbList.map(item=>{
                    return(
                        <>
                        <option key={item.id} value={item.id}>{item.name}</option>
                        </>
                    )
                })
            }
            
            </>
        )
    }
    }
    return(
        <>
        <main className="container-fluid dashboard">
            <div className="row m-3 w-50">
                <div className="col-12 mb-5">
                <select
                 className="form-control"
                 value={selectDb}
                 onChange={(e)=>{setSelectDb(e.target.value)}}
                >
                    <option>{"--select delivery boy--"}</option>
                    {dbOptions()}
                </select>
                </div>
                
            </div>
            <div className="col-md-12 card-1 mt-4">
              <table className="table table-hover align-middle">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">ID</th>
                    
                    <th scope="col">DB Name </th>
                    <th scope="col">Status </th>
                    <th scope="col">Distance </th>
                    <th scope="col">Order Count</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {
                    
                    dbList&&dbList.length>=0 &&
                    <>
                    {
                 dbList.map((obj) => {
                    return <DBItem db={obj} orderItem={item} key={obj.id} />;
                  })
                }
                  </> 
        }
                </tbody>
              </table>
            </div>
           
        </main>
        </>
    )
}

export default OrderAssign;