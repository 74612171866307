import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { STORE_API_PATH } from "../../../network/config/apiPaths";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { listAction } from "../../../network/store/action/TagResponseAction";
import Select from "react-select";
import { useToastMsg } from "../../hooks/useToastMsg";
import {
  BannerImageUplaodRequest,
  categoryImageUplaodRequest,
} from "../../../network/service/ImageUploadService";
import { useNavigate } from "react-router-dom";

function AddHomeBanner() {
  const navigate=useNavigate();
  const [inputValidation, setInputValidation] = useState({
    selectType: "",
    selectOption: "",
    bannerType: "",
    bannerName: "",

    bannerKeyword: "",
  });
  const [file, setFile] = useState("");

  const [tagResult, setTagResult] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const dispatch = useDispatch();

  const loadData = async () => {
    await dispatch(listAction(dispatch)).then((response) => {
      if (response.result) {
        console.log("tag...", response.result);
        setTagResult(response.result);
        setInputValidation({
          ...inputValidation,
          selectOption: response.result[0].id,
        });
      }

    });
  };

  const handleChange = (e) => {
    setInputValidation({
      ...inputValidation,
      [e.target.name]: e.target.value,
    });
  };

  const reset = () => {
    setInputValidation({
      ...inputValidation,
      selectType: "",
      selectOption: "",
      bannerName: "",
      bannerKeyword: "",
    });
    setFile("")
  };
  const [success, warning] = useToastMsg(
    "successfully created",
    "Requried all feilds",
    reset
  );

  const Validation = () => {
    inputValidation.selectType === "" ||
    inputValidation.selectType === "--Banner Type--" ||
    inputValidation.bannerName === "" ||
    inputValidation.selectOption==="--select tag--"||
    file===""
      ? warning()
      : uploadImage();
  };

  const dispalyTags = () => {
    return (
      <>
      <select
        className="mt-4 form-control"
        name="selectOption"
        value={inputValidation.selectOption}
        onChange={handleChange}
       
      >
        <option>{"--select tag--"}</option>
        {tagResult.map((obj, i) => {
          return <option value={obj.id} key={obj.id}>{obj.name}</option>;
        })}
      </select>
      {inputValidation.selectOption === "" && (
        <span className="text-danger">*Required</span>
      )}
    </>
    );
  };

  const uploadImage = async () => {
    let selectedOptionvalue = "";

    if (inputValidation.selectType === "Keyword") {
      selectedOptionvalue = inputValidation.bannerKeyword;
    } else if (inputValidation.selectType === "Tags") {
      selectedOptionvalue = inputValidation.selectOption;
    }

    const form_data = new FormData();
    form_data.append("file", file);
    let requestBody = {
      name: inputValidation.bannerName,
      option: inputValidation.selectType,
      optionValue: selectedOptionvalue,
      type: "HOME",
      fileData: form_data,
      latitude:0,
      longitude:0,
      radius:0,
    };
    console.log(inputValidation);
    console.log(requestBody);
    await dispatch(BannerImageUplaodRequest(requestBody)).then((response) => {
      console.log(response);
      response.data.responseCode===200 && success()
    });
  };

  const handleImageInputChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded != null) {
      setFile(fileUploaded);
      // uploadImage(fileUploaded);
    }
  };

  const displayKeyword = () => {
    return (
      <>
        <input
          placeholder="Banner Keyword"
          name="bannerKeyword"
          value={inputValidation.bannerKeyword}
          onChange={handleChange}
          className="form-control mt-4"
        />
        {inputValidation.bannerKeyword === "" && (
          <span className="text-danger">*Required</span>
        )}
      </>
    );
  };

  return (
    <>
      <ToastContainer />
      <div className="container-fluid dashboard d-flex flex-row justify-content-center">
        <div className="row m-5 w-50">
          <div className="col-12">
            <select
              className="form-control"
              name="selectType"
              value={inputValidation.selectType}
              onChange={handleChange}
            >
              <option>{"--Banner Type--"}</option>
              <option>Tags</option>
              <option>Keyword</option>
            </select>
            {(inputValidation.selectType === "" ||
              inputValidation.selectType === "--Banner Type--") && (
              <span className="text-danger">*Required</span>
            )}
          </div>
          <div className="col-12">
            {inputValidation.selectType === "Tags" && dispalyTags()}
            {inputValidation.selectType === "Keyword" && displayKeyword()}
          </div>

          <div className="col-12">
            <input
              placeholder="Banner Name"
              name="bannerName"
              value={inputValidation.bannerName}
              onChange={handleChange}
              className="form-control mt-4"
            />
            {inputValidation.bannerName === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
          <div className="col-12">
            <input
              type="file"
              name="image"
              value={inputValidation.image}
              onChange={handleImageInputChange}
              className="form-control mt-4"
              style={{
                border: "1px solid #80df6d",
                height: 150,
                textDecoration: "underline",
              }}
              placeholder="Browse/Upload"
            />
            {file === "" && (
              <span className="text-danger">*Required</span>
            )}
          </div>
          <div className="col-12 text-center">
            <button className="btn btn-success w-25 mt-4" onClick={Validation}>
              Submit
            </button>
          </div>
        </div>
        {console.log("form values..", inputValidation)}
      </div>
    </>
  );
}

export default AddHomeBanner;
