import { Link } from "react-router-dom"

function PaymentItem(){
    return(
        <>
        <tr>
            <td>1</td>
            <td><Link to="/uniqueStore">BVR</Link></td>
            <td>#Svr210524</td>
            <td>21-5-24</td>
            <td>20</td>
            <td>5000</td>
            <td>Paid</td>
            <td>22-5-24</td>
            <td>#3456789</td>
        </tr>
        </>
    )
}

export default PaymentItem