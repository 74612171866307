import { FaEdit, FaTrash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

function CustomerOrderItem(props) {
  const navigate=useNavigate();
  function Temp(status) {
    switch (status) {
      case "0":
        return <span style={{ color: "black" }}>Pending</span>;

      case "1":
        return <span style={{ color: "pinkAccent" }}>Accepted</span>;

      case "2":
        return <span style={{ color: "blueAccent" }}>Assigned</span>;

      case "3":
        return <span style={{ color: "yellow" }}>Picked Up</span>;
      case "4":
        return <span style={{ color: "green" }}>Delivered</span>;
      case "5":
        return <span style={{ color: "green" }}>Delivered</span>;
      case "6":
        return <span style={{ color: "red" }}>Cancelled</span>;
      case "7":
        return <span style={{ color: "black" }}>Rejected</span>;
      case "8":
        return (
          <span style={{ color: "0xffcc0d0d" }}>Order Cancel Request</span>
        );
    }
  }
  const storeDetails=()=>{
    navigate("/uniqueStore",{state:{storeId:props.storeId,storeName:props.storeName}})
  }
  return (
    <>
      <tr>
        <td> {props.id} </td>
        <td>
         {props.created_at}
        </td>
        <td> #EP0{props.id} </td>
        <td>{props.total_roundoff}</td>
        <td onClick={storeDetails}><Link> {props.storeName} </Link></td>
        
        <td>{props.address}</td>

        <td>{props.coupon_code}</td>

        <td>{Temp(props.order_status)}</td>
        <td className="align-middle">
          <div className="d-flex justify-content-center">

          <span className="edit-icon-btn">
              <FaEdit />
            </span>

            <span className="delete-icon-btn">
              <FaTrash />
            </span>

          </div>
        </td>
      </tr>
    </>
  );
}

export default CustomerOrderItem;
