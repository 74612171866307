import { useLocation } from "react-router-dom"
import CouponStoreItem from "./coupon-store-item"

function CouponStore(){
    const location=useLocation();
    const id=location.state !==null?location.state.storeId:"";
    const name=location.state!== null?location.state.storeName:"";
    console.log(id)
    return(
        <>
         <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="row justify-content-around tble">
            <div className="col-6">
              <h3 className="dashboard-title">Coupon Order History</h3>
            </div>
            <div className="mr-auto col-6 text-end">
              {/* <button
                type="button"
                className="mr-auto btn btn-purple-bg"
                onClick={() => {
                  dispatch(
                    updateFormInfo({
                      displayForm: true,
                      formData: {},
                    })
                  );
                }}
              >
                + Add Category
              </button> */}
            </div>
          </div>
        </div>
        <div className="row m-3">
          

          <div className="col-md-12 card-1">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Sno</th>
                  <th Scope="col">Store Name</th>
                  <th scope="col">Order Count</th>
                  <th scope="col">Order Amount</th>
                 
                </tr>
              </thead>
              
                <tbody>
                  <CouponStoreItem id={id} name={name}/>
                </tbody>
              
            </table>
          </div>
        </div>
      </main>
        </>
    )

}
export default CouponStore