const userRoleId = localStorage.getItem("userRoleId");

export function isDsplayAddProduct() {
  if (userRoleId === "1" || userRoleId === "2" || userRoleId === "3")
    return true;
  return false;
}

export function isStoreLogin() {
  if (userRoleId === "4" || userRoleId === "8") return true;

  return false;
}

export function isFranchisLogin() {
  if (userRoleId === "9") return true;
  return false;
}

export function isCms(){
  if(userRoleId==="10")return true;
  return false
}

export function isEmployee(){
  if(userRoleId==="3")return true;
  return false
}

export function isSuperAdmin(){
  if(userRoleId==="1")return true;
  return false
}
export function isAdmin(){
  if(userRoleId==="2")return true;
  return false
}
export function canCreateUser(){
  if(userRoleId==="1" ||userRoleId==="2")return true;
  return false
}
export function isCustomerSupport(){
  if(userRoleId==="6")return true;
  return false
}

export function isDsplayCrud() {
  if (userRoleId === "1") return true;
  return false;
}
