import { useState } from "react"
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig"
import { ORDER_API_PATH } from "../../network/config/apiPaths"
import { axiosApi } from "../../network/service/config/AaxiosUtil"

function DBItem(props) {
    const list = useState(props.db)
    console.log("list", list)
    const item=useState(props.orderItem)
    console.log("item",item[0])
    const assignDbApiCall = async () => {
        let requestBody = JSON.stringify({
            orderId: item[0].info.id,
            dbId: list[0].id,
            updatedBy: localStorage.getItem,
            userId: item[0].info.user_id,
            status: "2",
            statusText: "Delivery Boy Assigned",
            notes: "",
          
        })
      
        console.log("requestBody",requestBody)
        let data = await axiosApi.post(API_CONFIG.API_HOST + ORDER_API_PATH.ORDER_UPDATE, requestBody, {
          API_HEADERS
        })
        console.log(data)
        // data.status===200 && navigate(-1)
    }
    return (
        <>
            <td>{list[0].id}</td>
            <td>{list[0].name}</td>
            <td>{list[0].db_status}</td>
            <td>{list[0].distance}km</td>
            <td>{list[0].ordersCount}</td>
            <td>
            <div className="d-flex flex-row justify-content-center">
                    <button className="btn btn-primary" onClick={assignDbApiCall}>Assign</button>
                </div>
            </td>
        </>
    )
}
export default DBItem