import { Link, useNavigate } from "react-router-dom"

function CouponStoreItem(props){
    console.log(props.id)
    const navigate=useNavigate()
    const getStore=()=>{
        navigate("/uniqueStore",{state:{
            storeId:props.id,
            storeName:props.name,
        }})
    }
    return(
        <>
        <tr>
            <td>1</td>
            <td onClick={getStore}><Link >BVR</Link></td>
            <td>10</td>
            <td>1000</td>
            
        </tr>
        </>
    )
}

export default CouponStoreItem