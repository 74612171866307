import { useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { displayRemoveAlert } from "../../../redux/slice/formUpdateSlice";

function DeliveryBoyItem(props) {
  const dispatch=useDispatch()
  const navigate=useNavigate();
  const[product] = useState(props.item)
  const deleteItem = () =>{
    dispatch(displayRemoveAlert({ displayRemove: true, formData: { item:product },type:"users" }));
  }
  const deliveryOrders=()=>{
     navigate("/deliveryOrders",{state:{dbName:product.name,city:product.city}})
  }
  const editItem=()=>{
    navigate("/userEdit",{state:{item:product}})
  }
  return (
    <>
      <tr>
        <td> {product.id} </td>
        <td onClick={deliveryOrders}>
          <Link>{product.name}</Link>
        </td>
        <td> {product.phone} </td>
        <td> {product.franchiseOwner} </td>
        <td>{product.city}</td>
        <td className="align-middle">
            <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
                <input
                    class="form-check-input"
                    type="checkbox"s
                    role="switch"
                    checked={product.status === "ACTIVE" ? true : false}

                />
            </div>
        </td>
        
        <td className="align-middle">
                    <div className="d-flex justify-content-center">
                    <span className="edit-icon-btn" onClick={editItem}>
                      <FaEdit/>
                    </span>
                    {/* <span className="delete-icon-btn" onClick={deleteItem}>
                      <FaTrash/>
                    </span> */}
                    </div>
                </td>
      </tr>
    </>
  );
}

export default DeliveryBoyItem;
