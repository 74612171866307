import { useNavigate } from "react-router-dom";
import BannerItem from "../home/banner-item";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { BANNER_API_PATH } from "../../../network/config/apiPaths";
import BannerCategoryItem from "./category-banner-item";
import DeleteBanner from "../crudOperations/deleteBanner";
import { updateMessage } from "../../../redux/slice/toastSlice";
import { useDispatch, useSelector } from "react-redux";
import { deleteStatus } from "../../../redux/slice/formUpdateSlice";

function CategoryBanners() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [categoryList, setCategoryList] = useState([]);
  const [deleteBanner, setDeleteBanner] = useState(false);
  const showDeleteAlert = useSelector(deleteStatus);

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    CategoryApi();
  }, []);

  const CategoryApi = async () => {
    setLoading(true);
    const data = await axios.get(
      API_CONFIG.API_HOST + BANNER_API_PATH.LIST_PATH + "CATEGORY",
      {
        headers: API_HEADERS,
      }
    );
    console.log(data.data);
    setLoading(false);
    if (data.data.result != null) setCategoryList(data.data.result);
  };
  const onSubmit = () => {
    dispatch(
      updateMessage({
        display: true,
        message: "Record deleted",
      })
    );

    CategoryApi();
  };
  console.log(deleteBanner);

  return (
    <>
      {showDeleteAlert && (
        <DeleteBanner
          onSubmit={() => {
            onSubmit();
          }}
        />
      )}
      <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="row justify-content-around tble">
            <div className="col-10">
              <h3 className="dashboard-title">Category Banners</h3>
            </div>
            <div className="mr-auto col-2 text-end">
              <button
                type="button"
                className="mr-auto btn btn-outline-success"
                onClick={() => navigate("/AddCategoryBanner")}
              >
                Add New
              </button>
            </div>
          </div>
        </div>
        <div className="row m-3">
          <div className="col-md-12 card-1">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Sno</th>
                  <th scope="col">Banner Type</th>
                  <th scope="col">Selected Store</th>
                  <th scope="col">Tags</th>
                  <th scope="col">Banner Name</th>
                  <th scope="col">Banner Image</th>
                  {/* <th scope="col">Status</th> */}
                  <th scope="col">Actions</th>
                </tr>
              </thead>

              <tbody>
                {isLoading ? (
                  <>Loading ..</>
                ) : categoryList && categoryList.length > 0 ? (
                  <>
                    {categoryList.map((item, index) => {
                      return (
                        <BannerCategoryItem
                          result={item}
                          key={item.id}
                          id={index + 1}
                        />
                      );
                    })}
                  </>
                ) : (
                  <> No Data</>
                )}
              </tbody>
            </table>
            {console.log(deleteBanner)}
          </div>
        </div>
      </main>
    </>
  );
}

export default CategoryBanners;
