import { Link } from "react-router-dom"

function DeliveryOrderItem(){
    return(
        <>
        <tr>
            <td>1</td>
            <td>12-7-24</td>
            <td><Link>#EPo123</Link></td>
            <td>200</td>
            <td><Link>Vijetha</Link></td>
            <td>KK</td>
            <td>Miyapur</td>
            <td>Delivered</td>
            <td>COD</td>
            
        </tr>
        </>
    )
}

export default DeliveryOrderItem