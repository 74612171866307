import { API_CONFIG, IS_PRODUCTION } from "../network/config/ApiConfig";
import { dateRegex, dateRegexAllDate } from "./Regexp";
import moment from "moment";

export const getFutureDate = (fdp) => {
  const a = moment(fdp, "MM-DD-YYYY");
  const b = moment(moment(new Date()).format("MM-DD-YYYY"), "MM-DD-YYYY"); //current date

  return a.diff(b, "days");
};

export const validDate = (dob) => {
  return moment(dob, "MM-DD-YYYY").isValid();
};

export const firstPaymentDateValidation = (formValue) => {
  const fdp = formValue.firstDateOfPayment;

  if (!fdp) {
    return { result: true, message: "" };
  } else if (dateRegex.test(fdp)) {
    if (!validDate(fdp)) {
      return {
        result: false,
        message: "Please enter valid date",
      };
    }

    const fDate = getFutureDate(fdp);

    if (fDate >= 0)
      return {
        result: true,
        message: "",
      };
    else
      return {
        result: false,
        message: "You must select future date",
      };
  } else {
    return {
      result: false,
      message: "Please enter date in MM-DD-YYYY format",
    };
  }
};

export const DOBvalidation = (dob) => {
  if (!dob) {
    return { result: false, message: "Cannot be blank" };
  } else if (dateRegexAllDate.test(dob)) {
    if (!validDate(dob)) {
      return {
        result: false,
        message: "Please enter valid date",
      };
    }

    if (dateRegex.test(dob)) {
      return {
        result: true,
        message: "",
      };
    } else {
      return {
        result: false,
        message: "Date is out of range",
      };
    }
  } else {
    return {
      result: false,
      message: "Please enter valid date in MM-DD-YYYY format",
    };
  }
};

export const getImagePath=(imagePath)=>{
  if (imagePath.includes("http")){
    return imagePath
  }
  if (IS_PRODUCTION){
      return API_CONFIG.API_HOST+imagePath
  }
  else{
    return imagePath
  }
}