import { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listAction as categoryListAction } from "../../network/store/action/CategoryResponseAction";
import { listAction as subCategoryListAction } from "../../network/store/action/SubCategoryResponseAction";

import "../products/products.css";
import {
  formUpdateData,
  formUpdateStatus,
  updateFormInfo,
} from "../../redux/slice/formUpdateSlice";
import { useNavigate } from "react-router-dom";
import { updateMessage } from "../../redux/slice/toastSlice";
import AppToast from "../../components/appToast";
import {
  createUpdateAction,
  tagUpdateAction,
  taglistAction,
} from "../../network/store/action/CategoryResponseAction";
// import SelectableTagItem from "./selectable-tag-item";
import {
  onClearData,
  selectedItemsStatus,
} from "../../redux/slice/selectedItemsSlice";
import SelectableTagItem from "../products/selectable-tag-item";
import SelectableCatTagItem from "./cat-tag-item";

function CategoryTags() {
  // to get api data

  const dispatch = useDispatch();
  const showRequestForm = useSelector(formUpdateStatus);
  const formData = useSelector(formUpdateData);
  console.log("form data...",formData)
  const itemInfo = formData.item;
  console.log("item info...",itemInfo)
  // list of items
  const [resultList, setResultList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [categoryId, setCategoryId] = useState("");
  const selectedIds = useSelector(selectedItemsStatus);
  const navigate = useNavigate();
  useEffect(() => {
    if (itemInfo != undefined) {
        console.log("id",itemInfo.id)
      setCategoryId(itemInfo.id);
      loadData(itemInfo.id);
    }
  }, []);

  // useEffect(() => {

  // }, [productId]);

  const loadData = async (categoryId) => {
    setLoading(true);
    await dispatch(taglistAction({ categoryId: categoryId }, dispatch)).then(
      (response) => {
        setLoading(false);
        if (response.result) {
            console.log("response...",response)
          setResultList(response.result);
        }
      }
    );
  };

  const onUpdateClick = async () => {
    if (selectedIds && selectedIds.length > 0) {
      let itemObj = {
        categoryId: categoryId,
        tagIds: selectedIds.toString(),
      };

      await dispatch(tagUpdateAction(itemObj, dispatch)).then((response) => {
        console.log("createUpdateAction", response.result);
        if (response.result) {
          dispatch(
            updateMessage({
              display: true,
              message: "Product Created.",
            })
          );
          dispatch(onClearData());
          navigate(-1);
        }
      });
    } else {
      dispatch(
        updateMessage({
          display: true,
          message: "Select Tags",
        })
      );
    }
  };

  return (
    <>
      <AppToast />
      <main className="container-fluid dashboard">
        <div
          className="row justify-content-around tble"
          style={{ padding: "20px" }}
        >
          <div className="col-6">
            <h3 className="dashboard-title">Category Tags</h3>
          </div>
          <div className="mr-auto col-6 text-end">
            <button
              type="button"
              className="mr-auto btn btn-purple-bg"
              onClick={() => {
                onUpdateClick();
              }}
            >
              Update
            </button>

            <button
              type="button"
              className="mr-auto btn "
              onClick={() => {
                // onUpdateClick();
              }}
            >
              Clear Tags
            </button>
          </div>
        </div>

        {resultList && resultList.length > 0 ? (
          <div className="col-md-12" style={{ padding: "20px" }}>
            {resultList.map((item, i) => {
              return (
                <SelectableCatTagItem
                  item={item}
                  key={item.id}
                  itemStatus={item.categoryTagId === "NO" ? false : true}
                />
              );
            })}
          </div>
        ) : isLoading ? (
          <p>Loading Tags</p>
        ) : (
          <p>No Data found</p>
        )}
      </main>
    </>
  );
}
export default CategoryTags;
