import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { axiosApi } from "../../network/service/config/AaxiosUtil";
import { API_CONFIG,API_HEADERS } from "../../network/config/ApiConfig";
import { APP_VERSION_API_PATH } from "../../network/config/apiPaths";

function AppVersion() {
   const location=useLocation();
   const app=useState(location.state.app)
   console.log(app)
   const navigate=useNavigate()
   const [version,setVersion]=useState({
      uandroid:app[0].android_version,
      uios:app[0].ios_version,
      eandriod:app[0].android_employee_version,
      eios:app[0].ios_employee_version
   })
   const handleChange=(e)=>{
      let {name,value}=e.target
      console.log("name,value",name,value)
      setVersion({
         ...version,
         [name]: value
      })
   }
   const appVesionUpdate = async()=>{
      let requestbody=JSON.stringify({
          aev:version.eandriod,
          av:version.uandroid,
          iev:version.eios,
          iv:version.uios,
          id:app[0].id

      })
      let data=await axiosApi.post(API_CONFIG.API_HOST+APP_VERSION_API_PATH.UPDATE_APP,requestbody,{
          API_HEADERS
      })
      console.log(data)
      data.status===200&& navigate(-1)
   }
   return (
      <>
         <div className="container-fluid dashboard d-flex flex-row justify-content-center">
            <div className="row m-3 w-50">
               <div className="col-12 mb-4">
                  <h3>User App</h3>
               </div>
               <div className="col-12">
                  <input placeholder="Android" type="text" value={version.uandroid} name="uandroid" onChange={handleChange} className="form-control mb-3" />
               </div>
               <div className="col-12">
                  <input placeholder="ios" value={version.uios} name="uios" onChange={handleChange} className="form-control mb-3" />
               </div>
               <div className="col-12 mb-4">
                  <h3>Employee App</h3>
               </div>
               <div className="col-12">
                  <input placeholder="Android" value={version.eandriod} name="eandriod" onChange={handleChange} className="form-control mb-3" />
               </div>
               <div className="col-12">
                  <input placeholder="ios" value={version.eios} name="eios" onChange={handleChange} className="form-control mb-3" />
               </div>
               <div className="col-12">
                  <button className="btn btn-primary" onClick={appVesionUpdate}>Update</button>
               </div>
            </div>
            {console.log(version)}
         </div>
      </>
   )
}

export default AppVersion