import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { STORE_API_PATH, UOM_API_PATH } from "../../../network/config/apiPaths";
import { axiosApi } from "../../../network/service/config/AaxiosUtil";

function UpdateStorePrice() {
    const location=useLocation();
    
    const price = location.state.price;
    const from = location.state.from;
    console.log(price)
    const[uom,setUom]=useState([]);
   const[product,setProduct]=useState({
    quantity:price.qty,
    measure:price.measure,
    price:price.price,
    offerPrice:price.offer,
    cgst:price.c_gst,
    sgst:price.s_gst,
    app:price.gsp_percentage,
   })
   


   useEffect(()=>{
    measurements()
   },[])

   const measurements=async()=>{
     let data=await axios.get(API_CONFIG.API_HOST+UOM_API_PATH.LIST_PATH,{
        headers:API_HEADERS
     })
     console.log(data.data.result)
     setUom(data.data.result)
   }

   const handleChange=(e)=>{
    let {name,value}=e.target
    setProduct(
        {
            ...product,
            [name]:value
        }
    )
   }

   const listMeasure=()=>{
    return(
        <>
        {
            uom&&uom.length>=0 &&uom.map((obj,i)=>{
                return(
                    <option key={obj.id} value={obj.name}>{obj.name}</option>
                )
            })
        }
        </>
    )
   }

   const editItem = async() =>{
    let requestBody=JSON.stringify({
        storeId:price.store_id,
        gsp_percentage:product.app,
        productId:price.product_id,
        id:price.id,
        qty:product.quantity,
        measure:product.measure,
        quantity:product.quantity+product.measure,
        price:product.price,
        offer:product.offerPrice,
        s_gst:product.sgst,
        c_gst:product.cgst,
    })
   let apipath=API_CONFIG.API_HOST+STORE_API_PATH.PRICE_UPDATE
   console.log(apipath)
    let res=await axiosApi.post(apipath,requestBody,{
        headers:API_HEADERS
    }    
    )
    console.log(res)
   }

    return (
        <>
            <main className="container-fluid dashboard">
                <div className="row m-3">
                    <div className="col-12">
                        <h4>update Price</h4>
                        <h6>{price.product_name}</h6>
                    </div>
                </div>
                <div className="row m-3">
                    <div className="d-flex flex-row col-4">
                        <div className="" style={{marginRight:10}}>
                            <input 
                            placeholder="Quantity"
                            className="form-control"
                            name="quantity"
                            value={product.quantity} 
                            onChange={handleChange}
                            />
                        </div>
                        <div className="">
                            <select 
                            className="form-control"
                            name="measure" 
                            value={product.measure}
                            onChange={handleChange}
                            >
                                <option>{"--select measurement"}</option>
                                {listMeasure()}
                            </select>
                        </div>
                    </div>
                    <div className="col-3">
                        <input 
                        placeholder="Price" 
                        className="form-control" 
                        name="price" 
                        value={product.price} 
                        onChange={handleChange}
                        />
                    </div>
                    <div className="col-3">
                        <input 
                        placeholder="Offer" 
                        className="form-control" 
                        name="offerPrice" 
                        value={product.offerPrice} 
                        onChange={handleChange}
                        />
                    </div>

                </div>
                <div className="row m-3">
                    <div className="col-3">
                        <input 
                        placeholder="CGST" 
                        className="form-control" 
                        name="cgst" 
                        value={product.cgst}
                        onChange={handleChange}
                        />
                    </div>
                    <div className="col-3">
                        <input 
                        placeholder="SGST" 
                        className="form-control" 
                        name="sgst" 
                        value={product.sgst}
                        onChange={handleChange}
                        />
                    </div>
                    <div className="col-3">
                        <input 
                        placeholder="App %" 
                        className="form-control" 
                        name="app" 
                        value={product.app}
                        onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="row m-3 d-flex flex-row justify-content-center">
                    <div className="col-3 mt-5">
                    <button className="btn btn-success" onClick={()=>editItem()}>Update</button>
                    </div>
                </div>
            </main>
           {console.log(product)}
        </>
    )
}
export default UpdateStorePrice;