import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaRegEdit, FaTrash } from "react-icons/fa";
import { listAction } from "../../network/store/action/TagResponseAction";
import AddUpdateForm from "./add-update-form";
import Tagtem from "./tag-item";
import {
  deleteStatus,
  formUpdateStatus,
  updateFormInfo,
} from "../../redux/slice/formUpdateSlice";
import { updateMessage } from "../../redux/slice/toastSlice";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { TAG_API_PATH } from "../../network/config/apiPaths";
import axios from "axios";
import DeleteBanner from "../banners/crudOperations/deleteBanner";

function Tags() {
  const dispatch = useDispatch();
  const showRequestForm = useSelector(formUpdateStatus);
  // list of items
  const [resultList, setResultList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const showDeleteAlert=useSelector(deleteStatus);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    let data=await axios.get(API_CONFIG.API_HOST+TAG_API_PATH.LIST_PATH+"PRODUCT",{
      headers:API_HEADERS
    })
    console.log("data",data)
    setResultList(data.data.result)
    // await dispatch(listAction(dispatch)).then((reponse) => {
    //   if (reponse.result) {
       
    //     setResultList(reponse.result);
    //   }
    // });
  };
  function handleSearchClick(e) {
    const searchTerm = e.target.value;
    setSearchVal(searchTerm);
    if (searchTerm === "") {
      setResultList(resultList);
      setFilterList([]);
    } else {
      const filterBySearch = resultList.filter((item) => {
        if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          return item;
        }
      });
      setFilterList(filterBySearch);
    }
  }

  const onSubmit = () => {
    dispatch(updateFormInfo({ displayForm: false, formData: {} }));
    dispatch(
      updateMessage({
        display: true,
        message: "Record updated",
      })
    );
    setResultList([]);
    setSearchVal("");
    setFilterList([]);

    loadData();
  };
  const onDelete = () => {
    
    dispatch(
      updateMessage({
        display: true,
        message: "Record deleted",
      })
    );
    // setBannerList([]);
    loadData()

    // loadData();
  };


  return (
    <>
      {showRequestForm && (
        <AddUpdateForm
          onSubmit={() => {
            onSubmit();
          }}
          type="PRODUCT"
        />
      )}
      {showDeleteAlert && (
        <DeleteBanner
          onSubmit={() => {
            onSubmit();
          }}
         
        />
      )}
      <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="row justify-content-around tble">
            <div className="col-6">
              <h3 className="dashboard-title">Tags</h3>
            </div>
            <div className="mr-auto col-6 text-end">
              <button
                type="button"
                className="mr-auto btn btn-purple-bg"
                onClick={() => {
                  dispatch(
                    updateFormInfo({
                      displayForm: true,
                      formData: {},
                    })
                  );
                }}
              >
                + Add Tag
              </button>
            </div>
          </div>
        </div>
        <div className="row m-3">
          <div className="col-md-3">
            <div className="form-group">
              <label>Search Tags</label>
              <input
                type="text"
                name="name"
                className="form-control"
                value={searchVal}
                placeholder="Enter Tag name"
                onChange={(e) => handleSearchClick(e)}
              />
            </div>
          </div>
          <div className="col-md-12 card-1">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Id</th>
                  {/* <th scope="col">Image</th> */}
                  <th scope="col">Name</th>
                  {/* <th scope="col">Type</th> */}
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {searchVal === "" && resultList && resultList.length > 0 ? (
                <tbody>
                  {resultList.map((item, i) => {
                    return <Tagtem item={item} key={item.id} />;
                  })}
                </tbody>
              ) : filterList.length > 0 ? (
                <tbody>
                  {filterList.map((item, i) => {
                    return <Tagtem item={item} key={item.id} />;
                  })}
                </tbody>
              ) : (
                <p>No Data found</p>
              )}
            </table>
          </div>
        </div>
      </main>
    </>
  );
}

export default Tags;
