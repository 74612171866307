export const USER_API_PATH = {
  LOGIN_PATH: "User/login",
  REGISTRATION_PATH: "User/addUser",
  UPDATE_USER: "User/update",
  UPDATE_USER_PWD: "User/updatePassword",
  USER_INFO: "User/info?id=",
  LIST_PATH: "/User/listUsers?role=",
  DELETE_USER: "User/delete?id=",
};

export const CATGORY_API_PATH = {
  LIST_PATH: "Category/list",
  CREATE_UPDATE_PATH: "Category/update",
  PRODUCT_IMAGE: "Category/imageUpload",
  CATEGORY_TAGS_UPDATE: "category/updateTags",
  TAG_LIST_PATH: "Tag/categoryTags",
  DELETE_CATEGORY: "Category/delete?id=",
};

export const SUB_CATGORY_API_PATH = {
  LIST_PATH: "SubCategory/list",
  CREATE_UPDATE_PATH: "SubCategory/update",
  PRODUCT_IMAGE: "SubCategory/imageUpload",
};

export const PRODUCT_API_PATH = {
  LIST_PATH: "Product/list",
  PRODUCT_CREATE: "Product/update",
  PRODUCT_STATUS: "Product/updateProductStatus",
  PRODUCT_IMAGE: "Product/imageUpload",
  PRODUCT_NX_IMAGE: "Product/imageNxUpload",
  PRODUCT_IMAGE_LIST: "Product/productImages",
  PRODUCT_TAGS_UPDATE: "Product/updateTags",
  TAG_LIST_PATH: "Tag/productTags",
  SEARCH_LIST_PATH: "Product/adminSearchList",
};

export const TAG_API_PATH = {
  LIST_PATH: "Tag/list?type=",
  CREATE_UPDATE_PATH: "Tag/update",
  TAG_DELETE: "Tag/delete?id=",
  PRODUCT_TAG_DELETE:"Product/deleteTags",
  STORE_TAG_LIST_PATH: "Tag/storeTags",
  STORE_TAGS_UPDATE: "Tag/updateStoreTags",
};

export const STORE_API_PATH = {
  STORE_LIST: "Store/list",
  CREATE_STORE: "Store/update",
  UNIQUE_STORE_PRODUCTS: "Product/storeProductList?",
  UNIQUE_STORE_ORDERS: "Order/storeOrders?storeId=",
  UNIQUE_STORE_PAYMENTS: "",
  DELETE_STORE: "Store/delete?id=",
  PRICE_UPDATE:"ProductPrice/update",
  UNIQUE_ORDERS_COUNT:"Order/storeOrdersCount?"
};

export const ORDER_API_PATH = {
  ORDER_LIST: "Order/list",
  ORDER_DETAILS: "Order/orderInfo?orderId=",
  ORDER_UPDATE:"Order/updateOrder",
  ASSIGN_DB:"User/getNearbyUsers?",
 
};

export const UOM_API_PATH = {
  LIST_PATH: "Uom/list",
  CREATE_UPDATE_PATH: "Uom/update",
};

export const BANNER_API_PATH = {
  LIST_PATH: "Banner/list?type=",
  CREATE_BANNER: "Banner/createBanner",
  UPDATE_BANNER:"Banner/update",
  DELETE_BANNER: "Banner/delete?id=",
};

export const COUPONS_API_PATH = {
  COUPON_LIST_PATH: "PromoCode/list?type=",
  CREATE_COUPON: "PromoCode/update",
  DELETE_COUPON: "PromoCode/delete?id=",
  IMAGE_UPLOAD: "PromoCode/imageUpload",
};

export const APP_VERSION_API_PATH = {
  APP_LIST_PATH: "AppVersion/appVersionInfo",
  UPDATE_APP: "AppVersion/update",
};

export const DASHBOARD_API_PATH = {
  LIST_PATH: "Dashboard/getInfo",
  
};