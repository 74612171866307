import React, { useEffect, useState } from "react";

import axios from "axios";

import { useLocation, useNavigate } from "react-router-dom";

import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { ORDER_API_PATH, USER_API_PATH } from "../../../network/config/apiPaths";
import CustomerOrderItem from "./customer-order-item";


function CustomerOrders() {
  const navigate=useNavigate();
  const location=useLocation();
  const id=location.state!==null?location.state.id:"";
  const [apiResult, setApiResult] = useState([]);

  const [searchVal, setSearchVal] = useState("");
 
  const [filterList, setFilterList] = useState([]);

  

  useEffect(() => {
    UserList();
  }, []);

  const UserList = async () => {
    const data = await axios.get(
      API_CONFIG.API_HOST + ORDER_API_PATH.ORDER_LIST+"?userId="+id,
      {
        headers: API_HEADERS,
      }
    );
    setApiResult(data.data.result);
    setFilterList(data.data.result)
  };

  

  function handleSearchClick(e) {
    const searchTerm = e.target.value;
    setSearchVal(searchTerm);
    console.log("search Value", searchTerm);
      const filterBySearch = apiResult.filter((item) => {
        if (item.phone.includes(searchTerm)) {
          return item;
        }
      });
      setFilterList(filterBySearch);
   
  }

 
  
  return (
   
    <main className="container-fluid dashboard">
      <div className="row m-3">
        <div className="row justify-content-around tble">
          <div className="col-6">
            <h3 className="dashboard-title">Customers</h3>
          </div>
          <div className="mr-auto col-6 text-end">
            <button
              type="button"
              className="mr-auto btn btn-purple-bg"
              onClick={() => {navigate("/addNewUser")}}
            >
              + Add User
            </button>
          </div>
        </div>
      </div>
      <div className="row m-3">
        <div className="col-md-3">
          <div className="form-group">
            <label>Search</label>
            <input
              type="number"
              name="name"
              className="form-control"
              value={searchVal}
              onChange={handleSearchClick}
              placeholder="Enter Phone No"
            />
          </div>
        </div>
        
        <div className="col-md-12 card-1">
          <table className="table table-hover align-middle">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Sno</th>
                <th scope="col">Date & Time</th>
                <th scope="col">Order No</th>
                <th scope="col">Order Amount</th>
                <th scope="col">Recievers Name</th>
                <th scope="col">D.Location</th>
                <th scope="col">Coupon</th>
                <th scope="col">Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>

            <tbody>
              {filterList&& filterList.length>0&&filterList.map((obj) => {
                return <CustomerOrderItem {...obj} key={obj.id} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
}

export default CustomerOrders;