import React, { useState } from 'react'

import StorePaymentItem from './unique store items/Payment-Item'

function UniqueStorePayments() {
  const[isLoading,setIsLoading]=useState(false)
  const[selectMonth,setMonth]=useState("");
  const month=[
    {id:1,name:"JAN"},
    {id:2,name:"FEB"},
    {id:3,name:"MARCH"},
    {id:4,name:"APRIL"},
    {id:5,name:"MAY"},
    {id:6,name:"JUNE"},
    {id:7,name:"JULY"},
    {id:8,name:"AUG"},
    {id:9,name:"SEP"},
    {id:10,name:"OCT"},
    {id:11,name:"NOV"},
    {id:12,name:"DEC"},
  ]
  return (
    <>
     {/* <div className='row mb-3'>
        <div className='col-2'>
        <label>Month</label>
          <select value={selectMonth} className='form-control'>
            <option>{"--select month--"}</option>
            {
              month.map(item=>{
                return(
                  <option value={item.id}>{item.name}</option>
                )
              })
            }
          </select>
        </div>
        <div className='col-2'>

          <label>From</label>
          <input type="date" className='form-control'/>
            
          
        </div>
        <div className='col-2'>

          <label>To</label>
          <input type="date" className='form-control'/>
            
          
        </div>
       </div> */}
    {/* <div className="row mb-3 ">
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
             100 <br/> Orders Recieved
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      100 <br/> Orders Accepted
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      50 <br/> Orders Shipped
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      30 <br/> Orders Delivered
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      0 <br/> Orders Cancelled
      </div>
    </div>
  </div> */}
    <div className='row m-3'>
    <div className="col-md-12 card-1">
    <table className="table table-hover align-middle">
      <thead className="thead-dark">
        <tr>
          <th scope="col">Sno</th>
          
          <th scope="col">Settlement ID</th>
          <th scope="col">Date</th>
          <th scope="col">Total Orders</th>
          <th scope="col">Total Amount</th>
          <th scope="col">Payment</th>
          <th scope="col">Date</th>
          <th scope="col">Transaction ID</th>
        </tr>
      </thead>
      
        <tbody>
          <StorePaymentItem/>
        </tbody>
      
    </table>
  </div>
  </div>
  </>
  )
}

export default UniqueStorePayments